<template>
  <Dialog>
    <div class="px-md pb-md flex flex-col max-h-[92vh]">
      <div class="flex justify-center mb-sm mt-md">
        <Headline
          headline="user.account.addresses.deleteDialog.headline"
          level="h4"
        />
      </div>
      <div class="h-full overflow-auto">
        <div v-if="errors.length" class="mb-md">
          <UnknownError />
        </div>
        <div class="text-center">
          {{ t('user.account.addresses.deleteDialog.confirmationQuestion') }}
        </div>
        <div v-if="address" class="text-center my-md">
          <p class="font-bold">{{ address.name1 }},</p>
          <p v-if="address.name2" class="font-bold">{{ address.name2 }},</p>
          <p>{{ address.street }} {{ address.number }},</p>
          <p>{{ address.zip }} {{ address.city }}</p>
        </div>
        <ButtonBasic
          classes="w-full px-sm md:px-0 pt-sm"
          :label="t('user.account.addresses.deleteDialog.delete')"
          :type="EButtonType.SUBMIT"
          :btn-style="EButtonStyle.CTA"
          :loading-animation="isLoading"
          bg-color="var(--vdv-btn-base)"
          :disabled="isLoading"
          full-width
          @click="deleteAddress()"
        ></ButtonBasic>
        <div
          class="flex items-center justify-center w-full gap-2 cursor-pointer text-vdv-base mt-md"
          @click="deleteAddressDialog.close()"
        >
          <span class="anim__underline-link vdv-anim-link">
            {{ t('user.account.addresses.deleteDialog.cancel') }}
          </span>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/empty/dialogFrame.vue';
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import ButtonBasic from '~~/src/components/components/core/basic-button/vdv/basic-button.vue';
import { useDeleteAddress } from '../useDeleteAddress';
import UnknownError from '@/components/formFields/errors/unknown/unknownError.vue';
import useDeleteAddressDialog from '@/composables/dialogs/useDeleteAddressDialog';
import { Headline } from '@/complib';
const { t } = useTrans();

const deleteAddressDialog = useDeleteAddressDialog();
const address = deleteAddressDialog.getData();

const { errors, isLoading, deleteAddress } = useDeleteAddress();
</script>
